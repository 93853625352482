import defaultColors from "../util/default-colors.json"
import darkColors from "../util/dark-theme-colors.json"
import siteLayout from "../util/siteLayout.json"

const theme = {
  breakpoints: ["40em", "56em", "64em", "90em", "120em"],
  space: [0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fonts: {
    body:
      '"Open Sans", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
    heading:
      '"Open Sans", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
    monospace:
      "Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace",
  },
  a: {
    textDecoration: "none",
  },
  fontWeights: {
    light: 300,
    body: 400,
    heading: 700,
    heroHeading: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.3,
  },
  colors: {
    ...defaultColors,
    modes: {
      dark: {
        ...darkColors,
      },
    },
  },
  variants: {
    container: {
      maxWidth: siteLayout.containerLayout,
      m: "0 auto",
      px: ["20px", "20px", "40px"],
      py: "0",
    },
    markdown: {
      "h1, h2, h3, h4, h5, h6": {
        color: "global.headingColor",
      },
      "p code": {
        backgroundColor: "global.markdownCodeBgColor",
        color: "global.markdownCodeColor",
        textShadow: "none",
      },
      pre: {
        borderRadius: "12px",
      },
      img: {
        display: "block",
        borderRadius: "12px",
      },
      ".gatsby-resp-image-figure": {
        my: 6,
      },
      blockquote: {
        borderLeft: "3px solid",
        borderColor: "global.borderColor",
        px: 4,
        mx: 0,
        p: {
          fontSize: [3, 3, 4],
          fontWeight: "light",
        },
      },
      "blockquote, pre": {
        my: 5,
      },
      figure: {
        mx: 0,
        my: 3,
        figcaption: {
          mt: 1,
          fontSize: 0,
          textAlign: "ceter",
          color: "global.mutedColor",
        },
      },
      "p, li": {
        fontSize: 3,
        color: "global.textColor",
        fontWeight: "300",
      },
      a: {
        color: "global.primarylinkColor",
        "&:hover": {
          color: "global.primaryLinkHoverColor",
        },
      },
    },
    pageListPagination: {
      my: 8,
      textAlign: "center",
      ul: {
        m: 0,
        p: 0,
        display: "flex",
        justifyContent: "center",
        gap: 3,
        "li ": {
          listStyle: "none",
          margin: "0 5px",
          a: {
            display: "inline-block",
            fontWeight: "bold",
            lineHeight: "1",
            fontSize: "20px",
            color: "global.headingColor",
            "&:hover": {
              color: "global.primarylinkColor",
            },
            "&.is-active": {
              color: "global.mutedColor",
            },
          },
        },
      },
    },
    pagination: {
      my: 8,
      textAlign: "center",
      borderTop: "1px solid #eee",
      ul: {
        m: 0,
        p: 0,
        display: ["block", "flex"],
        justifyContent: "space-between",
        "li ": {
          listStyle: "none",
          margin: ["0 24px 24px", null, "24px 0"],
          a: {
            display: "inline-block",
            fontWeight: "300",
            lineHeight: "1",
            fontSize: "14px",
            color: "global.mutedColor",
            "&:hover": {
              color: "global.primarylinkColor",
            },
          },
          ".page-title": {
            display: "block",
            my: 3,
          },
          p: {
            fontSize: "18px",
            my: 3,
            color: "global.mutedColor",
          },
          img: {
            maxWidth: ["100%", "100%", "250px"],
            borderRadius: "12px",
          },
          "&:nth-of-type(1)": {
            textAlign: "left",
          },
          "&:nth-of-type(2)": {
            textAlign: "right",
          },
        },
      },
    },

    breadcrumb: {
      bg: "global.breadcrumbbgColor",
      ol: {
        variant: "variants.container",
        listStyle: "none",
        mx: "auto",
        my: 0,
        py: 3,
        fontSize: 2,
        fontWeight: "light",
        li: {
          display: "inline",
          mx: 2,
          "&:first-of-type": {
            ml: 0,
          },
        },
        a: {
          color: "global.breadcrumbColor",
          "&:hover": {
            color: "global.primaryLinkHoverColor",
            textDecoration: "underline",
          },
        },
      },
    },
    section: {
      py: 5,
    },
    pageHead: {
      maxWidth: ["100%", "80%", "60%", "50%"],
      mb: 3,
      h1: {
        m: 0,
        fontSize: [5, 6, 7],
        fontWeight: "600",
        color: "global.headingColor",
        letterSpacing: "1px"
      },
      p: {
        display: "block",
        m: 0,
        mt: 3,
        fontSize: [3, 4],
        color: "global.mutedColor",
      },
    },
    pageHomeHead: {
      title: {
        mt: 0,
        mb: 3,
        color: "global.headingColor",
        letterSpacing: "1px",
        fontSize: ["20px !important"],
      },
      tagline: {
        mt: 0,
        mb: [4, 5, 6],
        fontSize: [2, 3],
        color: "global.mutedColor",
      },
    },
    button: {
      primaryLink: {
        color: "button.primary.BgColor",
        textDecoration: "underline",
        transition: "color .3s ease",
        ".icon": {
          pl: 1,
          transition: "padding .3s ease",
        },
        "&:hover": {
          color: "button.primary.BgHoverColor",
          ".icon": {
            pl: 2,
          },
        },
      },
      primary: {
        py: 3,
        px: [3, 4],
        mr: [3, 4],
        display: "inline-flex",
        alignItems: "center",
        borderRadius: "12px",
        textDecoration: "none",
        appearance: "none",
        border: "none",
        fontSize: "inherit",
        color: "button.primary.textColor",
        bg: "button.primary.BgColor",
        "&:hover": {
          color: "button.primary.textHoverColor",
          bg: "button.primary.BgHoverColor",
        },
      },
      secondary: {
        py: 3,
        px: [3, 4],
        display: "inline-flex",
        alignItems: "center",
        borderRadius: "12px",
        textDecoration: "none",
        appearance: "none",
        border: "none",
        fontSize: "inherit",
        color: "button.secondary.textColor",
        bg: "button.secondary.BgColor",
        "&:hover": {
          color: "button.secondary.textHoverColor",
          bg: "button.secondary.BgHoverColor",
        },
      },
    },
    tag: {
      display: "inline-block",
      fontSize: 2,
      padding: "6px 12px",
      borderRadius: "4px",
      color: "global.tags.textColor",
      bg: "global.tags.BgColor",
      border: "1px solid",
      borderColor: "global.tags.borderColor",
      mr: "12px",
      mb: "12px",
      transition: "all .3s linear",
      "&:hover": {
        color: "global.tags.textHoverColor",
        borderColor: "global.tags.borderHoverColor",
      },
    },
    moreButton: {
      variant: "variants.button.primaryLink",
      color: "global.mutedColor",
      fontSize: [2, 3, 4],
      fontWeight: "heading",
      mr: 4,
    },
  },
}

export default theme
